<template>
  <div class="details-container">
    <v-row>
      <!-- Primeira Coluna: Informações Gerais -->
      <!-- <prev>{{ item }}</prev> -->
      <v-col cols="6">
        <h4>Detalhes da NF / Recibo: {{ item.nota_fiscal }}</h4>
        <ul>
          <li><strong>Fornecedor:</strong> {{ item.fornecedor.nome_fantasia }}</li>
          <li><strong>Razão Social:</strong> {{ item.fornecedor.razao_social }}</li>
          <li><strong>Valor NF:</strong> {{ item.valor | formatReal }}</li>
          <li><strong>Desconto:</strong> {{ item.desconto || 0 }}%</li>
          <li><strong>Valor Final NF:</strong> {{ item.valor_final | formatReal }}</li>
          <li><strong>Data de Envio:</strong> {{ item.data_envio | formatDatabr }}</li>
          <li><strong>Data de Recebimento:</strong> {{ item.data_recebimento | formatDatabr }}</li>
        </ul>
      </v-col>

      <!-- Segunda Coluna: Lista de Esterilizáveis e Equipamentos -->
      <v-col cols="6">
        <h4>Itens Associados</h4>
        <v-row dense>
          <!-- Esterilizáveis -->
          <v-col>
            <h5>Esterilizáveis</h5>
            <v-list>
              <template v-if="isLoading">
                <!-- Spinner para carregamento -->
                <v-list-item>
                  <v-list-item-content>
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                  v-for="esterilizavelId in parsedEsterilizavelIds"
                  :key="esterilizavelId"
                >
                  <v-list-item-content>
                    <span>ID: {{ findEsterilizavelById(esterilizavelId)?.id || 'Desconh' }} - Item: {{ findEsterilizavelById(esterilizavelId)?.nome.nome || 'Desconhecido' }}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>

          <!-- Equipamentos -->
          <v-col>
            <h5>Equipamentos</h5>
            <v-list>
              <template v-if="isLoading">
                <!-- Spinner para carregamento -->
                <v-list-item>
                  <v-list-item-content>
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                  v-for="equipamentoId in parsedEquipamento"
                  :key="equipamentoId"
                >
                  <v-list-item-content>
                    <span>ID: {{ findEquipamentoById(equipamentoId)?.id || 'Desconhecido' }} - Item: {{ findEquipamentoById(equipamentoId)?.nome || 'Desconhecido' }}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "@/http";

export default {
  name: "DetalhesEsterilizacao",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      esterelizavel: [], // Lista completa de esterilizáveis
      equipamento: [], // Lista completa de equipamentos
      isLoading: false,
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
        const [esterilizavelResponse, equipamentoResponse] = await Promise.all([
          api.get("equipamentos/esterelizavels/"),
          api.get("equipamentos/equipamentos/"),
        ]);

        this.esterelizavel = esterilizavelResponse.data;
        console.log( this.esterelizavel)
        this.equipamento = equipamentoResponse.data;
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        this.isLoading = false;
      }
    },
    findEsterilizavelById(id) {
      return this.esterelizavel.find((item) => item.id === id);
    },
    findEquipamentoById(id) {
      return this.equipamento.find((item) => item.id === id);
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    // Converte `ids_esterilizaveis` de string para array de números
    parsedEsterilizavelIds() {
      return this.item.ids_esterilizaveis
        ? this.item.ids_esterilizaveis.split(",").map((id) => parseInt(id.trim(), 10))
        : [];
    },
    parsedEquipamento() {
      return this.item.ids_ids_equipamentos
        ? this.item.ids_ids_equipamentos.split(",").map((id) => parseInt(id.trim(), 10))
        : [];
    },
  },
};
</script>

<style scoped>
.details-container {
  padding: 20px;
}
h4 {
  margin-bottom: 15px;
}
h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
